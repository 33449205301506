import React, {useContext} from 'react';
import Branding from 'assets/images/gpn/branding.png';
import {downloadPdf} from 'helpers/utils';
import { ModalContext } from "context/ModalContext";
import classNames from 'classnames';

export const BrandingPackage = () => {
  const { setIsVisibleModal } = useContext(ModalContext);

  const handleDownloadClick = () => {
    const isSignedIn = localStorage.getItem('isSignedIn');

    if (isSignedIn) {
      downloadPdf('Branding_elements.zip');
    } else {
      setIsVisibleModal(true);
    }
  };

  return (
    <div className={classNames('flex justify-center relative mt-44 before:content-[""] before:absolute before:top-[51%] before:-translate-y-1/2 before:left-0 before:right-0 before:bg-orange before:w-full before:h-64',
      'lg:mt-20 sm:!mt-10 lg:before:top-[50%] md:before:!top-[53%] lg:before:h-56 sm:before:hidden sm:bg-orange sm:py-6')}>
      <div className='flex w-full px-32 z-10 items-center lg:px-16 sm:flex-col-reverse sm:!px-4'>
        <img className='w-[48%] sm:w-full' src={Branding} alt='branding package'/>
        <div className='flex flex-col flex-1 ml-20 lg:ml-10 sm:!ml-0 sm:w-full sm:items-center sm:text-center sm:mb-6'>
          <p className='uppercase text-blue text-[32px] font-bold lg:text-2xl sm:!text-xl'>THE BRANDING PACKAGE</p>
          <div className='flex flex-col items-start my-10 gap-y-2 sm:!my-4 sm:items-center sm:text-center'>
            <p className='text-white uppercase text-xl lg:text-base'>LOGO</p>
            <p className='text-white uppercase text-xl lg:text-base'>FONTS</p>
            <p className='text-white uppercase text-xl lg:text-base'>PRODUCT PITCH DECK</p>
            <p className='text-white uppercase text-xl lg:text-base'>MESSAGE FROM THE FOUNDERS</p>
            <p className='text-white uppercase text-xl lg:text-base'>PRODUCT CATALOGUE</p>
            <p className='text-white uppercase text-xl lg:text-base'>PRINT FILES</p>
          </div>
          <button onClick={handleDownloadClick} className={`self-start text-sm uppercase p-2.5 !leading-none bg-blue rounded-xl text-white duration-200	border-2 border-blue min-lg:hover:bg-white min-lg:hover:text-blue min-lg:!hover:delay-0 xl:text-base sm:w-full`}>DOWNLOAD</button>
        </div>
      </div>
    </div>
  );
};
