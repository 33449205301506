import React from 'react';
import Teammates from 'assets/images/gpn/teammates.png';
import classNames from 'classnames';
import { ProductCatalogue, VRExperience, CaseStudies, BrandingPackage, VideosCarousel } from './components';
import { LoginModal } from 'components';
import { ModalProvider } from 'context';

export const Gpn = () => {
  return (
    <ModalProvider>
      <div className='flex flex-col'>
        <div className={classNames('flex w-full relative -mt-24 sm:mt-0',
          'before:content-[""] before:absolute before:bottom-0 before:w-full before:h-2/3 before:bg-blue sm:before:h-[90%]'
        )}>
          <div className='flex w-full justify-end'>
            <div className='flex flex-col items-center text-white text-5xl absolute left-24 top-[57%] z-20 lg:text-3xl lg:left-16 sm:!text-lg sm:!left-4 sm:!top-[17%]'>
              <p className='font-bold mb-3 sm:mb-0'>GLOBAL PARTNER NETWORK</p>
              <p>CONTENT CENTER</p>
            </div>
            <img className='self-end w-[45%] h-[97%] z-10 sm:w-[55%]' src={Teammates} />
          </div>
        </div>
        <ProductCatalogue/>
        <VRExperience/>
        <CaseStudies/>
        <BrandingPackage/>
        <VideosCarousel/>
        <LoginModal/>
      </div>
    </ModalProvider>
  );
};
