import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useScrollY } from "hooks";
import cn from 'classnames';
import Alexis from 'assets/images/alexis.png';
import Carolina from 'assets/images/girl.png';
import Francesca from 'assets/images/Francesca.png';
import Junus from 'assets/images/junus-circle.png';
import Lilit from 'assets/images/lilit.png';
import Rami from 'assets/images/rami.png';
import { useWindowDimensions } from "hooks";

export const AboutSection4 = () => {
  const blockRef = useRef();
  const joinUsRef = useRef();
  const textRef = useRef();
  const scrollY = useScrollY();
  const [joinUsActive, setJoinUsActive] = useState(false);
  const [textRefActive, setTextRefActive] = useState(false);
  const [active, setActive] = useState(false);
  const { width } = useWindowDimensions();

  useEffect(() => {
    if (width > 900) {
      if (joinUsRef.current.getBoundingClientRect().y < window.innerHeight * 0.7) setJoinUsActive(true);
      if (textRef.current.getBoundingClientRect().y < window.innerHeight * 0.7) setTextRefActive(true);
    }
  }, [scrollY, width]);

  const handleScroll = useCallback(() => {
    if (blockRef.current.offsetTop + window.innerHeight * 0.9 < window.scrollY) {
      if (!active) setActive(true);
    } else {
      active && setActive(false);
    }
  }, [active]);

  useEffect(() => {
    if (width > 900) window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll, width]);

  return (
    <section ref={blockRef} className='flex items-end overflow-hidden pt-32 md:pt-20'>
      {width > 900 && <div className='flex'>
        <div className="w-[75vw] h-[75vw] relative rounded-full border-8 border-white -translate-x-[45%] lg:-translate-x-[40%]">
          <div id='careers' className='w-[70%] h-[70%] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>
            <span className='bg-gray rounded-full absolute w-full h-full top-0 left-0' />
            <div ref={joinUsRef} className='absolute top-1/2 left-1/2  -translate-y-1/2 max-w-[20rem] flex flex-col z-20 text-creme mix-blend-difference lg:left-[40%] lg:max-w-[18rem]'>
              <p className={cn('text-4xl font-bold mb-8 translate-x-1/2 opacity-0 duration-300 lg:text-2xl', { '!translate-x-0  opacity-100': joinUsActive })}>JOIN US!</p>
              <p className={cn('-translate-x-1/2 opacity-0 duration-300 text-justify lg:text-sm', { '!translate-x-0  opacity-100': joinUsActive })}>We are on an ambitious mission to transform and empower people using VR and ethical AI. We value the contributions of our team, and as we grow,
                we’re always looking for talented, driven people to join our ranks!<br /><br />
                Looking for a job in tech? Send us your CV at <a href='mailto:contact@wondder.io' className='font-bold'>contact@wondder.io</a></p>
            </div>
          </div>
          <div className={`absolute w-[50%] h-[1px] bg-transparent top-1/2 origin-right	grayscale rotate-[235deg]`}>
            <div className={`absolute right-full translate-x-1/2 -translate-y-1/2 w-[170px] h-[170px] lg:w-28 lg:h-28 rounded-full overflow-hidden rotate-[130deg]`}>
              <img src={Rami} className='w-full h-full' />
            </div>
          </div>
          <div className={`absolute w-[50%] h-[1px] bg-transparent top-1/2 origin-right rotate-[213deg]`}>
            <div className={`absolute right-full translate-x-1/2 -translate-y-1/2 w-[170px] h-[170px] lg:w-28 lg:h-28 rounded-full overflow-hidden rotate-[140deg]`}>
              <img src={Lilit} className='w-full h-full' />
            </div>
          </div>
          <div className={`absolute w-[50%] h-[1px] bg-transparent top-1/2 origin-right rotate-[191deg]`}>
            <div className={`absolute right-full translate-x-1/2 -translate-y-1/2 w-[170px] h-[170px] lg:w-28 lg:h-28 rounded-full overflow-hidden rotate-[165deg]`}>
              <img src={Junus} className='w-full h-full' />
            </div>
          </div>
          <div className={`absolute w-[50%] h-[1px] bg-transparent top-1/2 origin-right rotate-[169deg]`}>
            <div className={`absolute right-full translate-x-1/2 -translate-y-1/2 w-[170px] h-[170px] lg:w-28 lg:h-28 rounded-full overflow-hidden rotate-[192deg]`}>
              <img src={Francesca} className='w-full h-full' />
            </div>
          </div>
          <div className={`absolute w-[50%] h-[1px] bg-transparent top-1/2 origin-right rotate-[147deg]`}>
            <div className={`absolute right-full translate-x-1/2 -translate-y-1/2 w-[170px] h-[170px] lg:w-28 lg:h-28 rounded-full overflow-hidden rotate-[-148deg]`}>
              <img src={Carolina} className='w-full h-full' />
            </div>
          </div>
          <div className={`absolute w-[50%] h-[1px] bg-transparent top-1/2 origin-right rotate-[125deg]`}>
            <div className={`absolute right-full translate-x-1/2 -translate-y-1/2  w-[170px] h-[170px] lg:w-28 lg:h-28 rounded-full overflow-hidden rotate-[-122deg]`}>
              <img src={Alexis} className='w-full h-full' />
            </div>
          </div>
        </div>
      </div>}
      {width < 901 && <div className='flex flex-col items-center gap-y-12  mx-auto text-center px-8 w-full'>
        <p className='text-4xl text-white font-bold'>JOIN US!</p>
        <div className='flex w-full justify-center gap-12 '>
          <div className={` w-36 h-36 rounded-full overflow-hidden`}>
            <img src={Junus} className='w-full h-full' />
          </div>
          <div className={` w-36 h-36 rounded-full overflow-hidden`}>
            <img src={Carolina} className='w-full h-full' />
          </div>
        </div>
        <p className='text-base text-white max-w-[60vw]'>We are on an ambitious mission to transform and empower people using VR and ethical AI. We value the contributions of our team, and as we grow, we’re always looking for talented, driven people to join our ranks!</p>
        <div className='flex w-full justify-center gap-12'>
          <div className={` w-36 h-36 rounded-full overflow-hidden`}>
            <img src={Alexis} className='w-full h-full' />
          </div>
          <div className={` w-36 h-36 rounded-full overflow-hidden`}>
            <img src={Rami} className='w-full h-full' />
          </div>
        </div>
        <p className='text-base text-white max-w-[60vw]'>Looking for a job in tech? Send us your CV at <a href='mailto:contact@wondder.io' className='font-bold'>contact@wondder.io</a></p>
        <div className='flex w-full justify-center gap-12'>
          <div className={` w-36 h-36 rounded-full overflow-hidden`}>
            <img src={Francesca} className='w-full h-full' />
          </div>
          <div className={` w-36 h-36 rounded-full overflow-hidden`}>
            <img src={Lilit} className='w-full h-full' />
          </div>
        </div>
        <p className='text-base text-white max-w-[60vw]'>Our team hails from a dozen countries and many fields of expertise: from Integral Psychology and Film to Computer Science and Electrical Engineering. Together we share the vision of an awakened humanity, happy, healthy, aware, caring for themselves with joy!</p>
      </div>}
      {width > 900 && <p ref={textRef} className={cn('mix-blend-difference text-base text-creme font-normal text-justify z-20 absolute bottom-32 right-20 max-w-[27rem] w-full translate-x-1/2 opacity-0 duration-300 lg:max-w-[22rem]', { '!translate-x-0  opacity-100': textRefActive })}>Our team hails from a dozen countries and many fields of expertise: from Integral Psychology and Film to Computer Science and Electrical Engineering.
        Together we share the vision of an awakened humanity, happy, healthy, aware, caring for themselves with joy!</p>}
    </section>
  );
};
