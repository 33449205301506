import React from 'react';
import Alexis from 'assets/images/alexis.png';
import Lilit from 'assets/images/lilit.png';
import Rami from 'assets/images/rami.png';
import Mihai from 'assets/images/mihai-rounded.png';

export const ContactUsTeamSection = () => {

  return (
    <div className='flex px-48 flex-wrap justify-center mt-64 xl:px-16 lg:mt-48 sm:flex-col sm:items-center sm:!mt-20'>
      <div className='flex flex-col w-1/2 md:w-1/2 sm:!w-full items-center mb-20 sm:mb-10'>
        <div className='w-64 h-64 rounded-full grayscale lg:w-36 lg:h-36'>
          <img src={Mihai} className='w-full h-full' />
        </div>
        <div className='flex flex-col justify-center items-center text-2xl mt-6 lg:text-lg sm:text-center'>
          <p className='text-white font-bold'>MIHAI STREZA</p>
          <p className='text-white font-normal mb-6 lg:mb-3'>Investor Relations & Sales</p>
          <a className='text-white font-normal duration-300 hover:text-orange' href="mailto:mihai@wondder.io">mihai@wondder.io</a>
        </div>
      </div>
      <div className='flex flex-col w-1/2 md:w-1/2 sm:!w-full  items-center mb-20 sm:mb-10'>
        <div className='w-64 h-64 rounded-full grayscale lg:w-36 lg:h-36'>
          <img src={Rami} className='w-full h-full' />
        </div>
        <div className='flex flex-col justify-center items-center text-2xl mt-6 lg:text-lg'>
          <p className='text-white font-bold'>RAMI HAIDAR</p>
          <p className='text-white font-normal mb-6 lg:mb-3'>Marketing & PR Lead</p>
          <a className='text-white font-normal duration-300 hover:text-orange' href="mailto:rami.haidar@wondder.io">rami.haidar@wondder.io</a>
        </div>
      </div>
      <div className='flex flex-col w-1/2 md:w-1/2 sm:!w-full items-center mb-20 sm:mb-10'>
        <div className='w-64 h-64 rounded-full grayscale lg:w-36 lg:h-36'>
          <img src={Alexis} className='w-full h-full' />
        </div>
        <div className='flex flex-col justify-center items-center text-2xl mt-6 lg:text-lg'>
          <p className='text-white font-bold'>ALEXIS OLIVARI</p>
          <p className='text-white font-normal mb-6 lg:mb-3'>Lead Developer</p>
          <a className='text-white font-normal duration-300 hover:text-orange' href="mailto:alexis@wondder.io">alexis@wondder.io</a>
        </div>
      </div>
      <div className='flex flex-col w-1/2 md:w-1/2 sm:!w-full items-center mb-20 sm:mb-10'>
        <div className='w-64 h-64 rounded-full grayscale lg:w-36 lg:h-36'>
          <img src={Lilit} className='w-full h-full' />
        </div>
        <div className='flex flex-col justify-center items-center text-2xl mt-6 lg:text-lg'>
          <p className='text-white font-bold'>LILIT KHACHATRYAN</p>
          <p className='text-white font-normal mb-6 lg:mb-3'>B2B Marketing Manager</p>
          <a className='text-white font-normal duration-300 hover:text-orange' href="mailto:lilit.khachatryan@wondder.io">lilit.khachatryan@wondder.io</a>
        </div>
      </div>

    </div>
  );
};
