import React from 'react';
import GpnCatalogueCover from 'assets/images/gpn/gpn_cover.png';
import {downloadPdf} from 'helpers/utils';

export const ProductCatalogue = () => {
  return (
    <div className='flex justify-center items-center w-full relative py-14 px-40 lg:px-16 lg:py-12 sm:flex-col sm:items-center sm:!px-4 sm:!py-10'>
      <img className=' w-[400px] z-10 lg:w-72' src={GpnCatalogueCover} />
      <div className='flex flex-col ml-20 lg:ml-10 sm:!ml-0 sm:items-center sm:mt-5'>
        <p className='font-bold text-4xl mb-5 lg:text-3xl sm:!text-xl'>2024 PRODUCT CATALOGUE</p>
        <div className='flex justify-between gap-x-10 sm:w-full'>
          <a target='_blank' rel='noopener noreferrer' href='/files/2024 Catalogue EN (3).pdf' className={`flex-1 text-center text-2xl p-2.5 !leading-none bg-orange rounded-xl text-white duration-200	border-2 border-orange min-lg:hover:bg-white min-lg:hover:text-orange min-lg:!hover:delay-0 xl:text-base`}>PREVIEW</a>
          <button onClick={() => downloadPdf('2024 Catalogue EN (3).pdf')} className={`flex-1 text-2xl p-2.5 !leading-none bg-orange rounded-xl text-white duration-200	border-2 border-orange min-lg:hover:bg-white min-lg:hover:text-orange min-lg:!hover:delay-0 xl:text-base`}>DOWNLOAD</button>
        </div>
      </div>
    </div>
  );
};
