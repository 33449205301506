import React, { useEffect, useState, useRef } from 'react';
import { OrangeButton } from 'components';
import SectionBg from 'assets/images/home-hero.png';
import SectionBgMobile from 'assets/images/home-hero-mobile.png';
import { isBlockVisibleOnScreen } from "helpers/utils";
import cn from 'classnames';
import { useIsMobile } from "hooks";

export const HomeSection1 = () => {
  const sectionRef = useRef();
  const mobile = useIsMobile();
  const [active, setActive] = useState(false);

  useEffect(() => {
    isBlockVisibleOnScreen(sectionRef.current, setActive, [0, 1]);
  }, []);

  return (
    <div ref={sectionRef} className='flex relative pt-[53.6%] overflow-hidden sm:pt-[154.9%]'>
      <img className='absolute top-0 left-0 w-full h-full object-contain md:object-[11.5%]' src={mobile ? SectionBgMobile : SectionBg} alt="vr background" loading="lazy" />
      <span className='hidden md:block absolute top-0 right-0 w-full h-full  bg-blue pointer-events-none	opacity-30'></span>
      <div className={cn(
        'flex flex-col items-end z-20 max-w-[38rem] absolute top-1/2 -translate-y-3/4 right-28 text-right xl:max-w-[27rem] xl:right-16',
        'md:top-[10%] md:translate-y-0 md:!right-8 md:!max-w-[23rem]',
        'sm:bottom-4 sm:!top-[unset] sm:items-start sm:text-left sm:left-8'
      )}>
        <p className={`text-5xl font-semibold text-white xl:text-3xl ${active ? 'duration-300 translate-x-0 opacity-100' : 'duration-0 -translate-x-1/4 opacity-0'}`}>Enter the world of unlimited potential.</p>
        <p className={`text-2xl font-semibold text-white my-14 xl:text-lg xl:my-8 md:text-base md:!mt-6 md:!mb-4 ${active ? 'duration-300 delay-100 translate-x-0  opacity-100' : 'duration-0 -translate-x-1/4 opacity-0'}`}>At wondder we develop immersive VR learning methods based on proven scientific research to reduce bias, grow mental health, and develop a more inclusive culture.</p>
        <OrangeButton href="/contact" className={active ? 'duration-300 delay-300 translate-x-0 opacity-100 hover:delay-0' : 'duration-0 -translate-x-1/4 opacity-0'}>Book a Demo Call</OrangeButton>
      </div>
    </div>
  );
};
