import React from 'react';
import { OrangeButton, VideoPlayer } from 'components';
import { Swiper, SwiperSlide} from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { useWindowDimensions } from "hooks";
import 'swiper/css';
import 'swiper/css/navigation';

const videos = [
  'https://player.vimeo.com/video/915216156?h=c5376c5c42&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
  'https://player.vimeo.com/video/914242970?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
  'https://player.vimeo.com/video/944758155?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
  'https://player.vimeo.com/video/882064105?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
  'https://player.vimeo.com/video/831647913?h=c0050f163c&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
  'https://player.vimeo.com/video/831644945?h=11da46f609&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
  'https://player.vimeo.com/video/914766764?h=9641bae220&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
];

export const VideosCarousel = () => {
  const { width } = useWindowDimensions();
  return (
    <div className='relative flex flex-col w-full pt-40 pb-32 px-20 border-b-8 border-blue lg:pt-32 lg:pb-20 sm:!pt-20 sm:!px-4'>
      <div className='flex items-center px-32 mb-36 lg:px-16 lg:mb-28 sm:!mb-10 sm:justify-start sm:!px-0'>
        <p className='text-[54px] text-blue uppercase font-extrabold mr-8 lg:text-4xl sm:!text-2xl sm:mr-4'>Video links</p>
        <span className='flex-1 bg-orange h-3 lg:h-2 sm:!h-1' />
      </div>
      <button className="arrow-left arrow absolute bottom-[35%] left-5 z-20 text-orange text-5xl sm:text-4xl sm:left-3 sm:bottom-[42%]">&#11164;</button>
      <button className="arrow-right arrow absolute bottom-[35%] right-5 z-20 text-orange text-5xl sm:text-4xl sm:right-3 sm:bottom-[42%]">&#11166;</button>
      <Swiper
        slidesPerView={width > 1024 ? 3 : width > 550 ? 2 : 1}
        spaceBetween={24}
        draggable
        className="w-full"
        navigation={{ nextEl: ".arrow-right", prevEl: ".arrow-left" }}
        modules={[Navigation]}

      >
        {videos.map((i, k) => {
          return (
            <SwiperSlide key={k} className='!flex !flex-col '>
              <VideoPlayer className='w-full pt-[55.25%] sm:w-[80%] sm:mx-auto sm:pt-[47.25%]' src={i} animatable={false} isHome id={i.slice(31,40)} />
              <OrangeButton href={i} target="_blank" rel='noopener noreferrer' className='mt-6 self-center !text-sm md:!text-sm sm:mt-3' >View video in new tab</OrangeButton>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};
