import React, { useState, useEffect, useRef } from 'react';
import { OrangeButton, Input, Textarea } from 'components';
import { ContactUsTeamSection } from './components';
import FbIcon from 'assets/images/facebook.png';
import LinkedinIcon from 'assets/images/linkedin.png';
import InstaIcon from 'assets/images/instagram.png';
import { useIsMobile } from "hooks";
import emailjs from '@emailjs/browser';
import { emailJsPublicKey, emailJsServiceId, emailJsTemplateIdContactForm } from 'helpers/constants';

export const ContactUs = () => {
  const form = useRef();
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const mobile = useIsMobile();

  useEffect(() => {
    setIsPageLoaded(true);
  }, []);

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true);

    emailjs
      .sendForm(emailJsServiceId, emailJsTemplateIdContactForm, form.current, {
        publicKey: emailJsPublicKey,
      })
      .then(
        () => {
          form.current.reset();
        },
        (error) => {
          // eslint-disable-next-line no-console
          console.log('FAILED...', error.text);
        },
      )
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className='flex flex-col bg-dark-blue px-20 py-36 lg:px-10 lg:py-24 sm:py-16 sm:px-12'>
      <div className='flex items-center px-20 md:px-0 sm:flex-col-reverse'>
        <form ref={form} onSubmit={sendEmail} className='email-form flex flex-col max-w-[25rem] w-full xl:max-w-80 lg:!max-w-56 md:mt-8 sm:min-w-full'>
          <Input name="user_name" className='delay-300' labelColor="text-white" label='Name' type='text' isActive={isPageLoaded} />
          <Input name="user_email" className='delay-500' labelColor="text-white" label='Business e-mail' type='email' isActive={isPageLoaded} />
          <Textarea name="user_message" label='Your message' labelColor="text-white" isActive={isPageLoaded} />
          <OrangeButton className={` mt-4 flex  items-center justify-center ${isPageLoaded ? 'duration-300 delay-1000 translate-x-0 opacity-100 hover:delay-0' : 'duration-300 -translate-x-1/4 opacity-0'}`}>
            {loading ? "SENDING" : "SUBMIT"}
            {loading && <svg aria-hidden="true" className="w-6 h-6 animate-spin ml-2" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#173658" />
            </svg>}
          </OrangeButton>
        </form>
        <div className='flex flex-col ml-40 lg:ml-20 sm:!ml-0'>
          <div className='flex flex-col text-white'>
            <h2 className={`text-4xl lg:text-2xl sm:!text-xl font-bold mb-12 lg:mb-8 sm:!mb-6 duration-500 delay-150 ${isPageLoaded ? 'opacity-100 translate-x-0' : 'opacity-0 -translate-x-1/2'}`}>IMPRESSUM</h2>
            <p className={`text-xl lg:text-base sm:!text-sm font-normal mb-2 duration-500 delay-200 ${isPageLoaded ? 'opacity-100 translate-x-0' : 'opacity-0 -translate-x-1/2'}`}>VaRc GmbH</p>
            <p className={`text-xl lg:text-base sm:!text-sm font-normal mb-2 duration-500 delay-300 ${isPageLoaded ? 'opacity-100 translate-x-0' : 'opacity-0 -translate-x-1/2'}`}>Seat of the company: Berlin</p>
            <p className={`text-xl lg:text-base sm:!text-sm font-normal mb-2 duration-500 delay-[400ms] ${isPageLoaded ? 'opacity-100 translate-x-0' : 'opacity-0 -translate-x-1/2'}`}>Register Court: Amtsgericht Charlottenburg, HRB 213567 B</p>
            <p className={`text-xl lg:text-base sm:!text-sm font-normal mb-2 duration-500 delay-500 ${isPageLoaded ? 'opacity-100 translate-x-0' : 'opacity-0 -translate-x-1/2'}`}>Company Address: Zähringerstraße 26, 10707 Berlin, DE</p>
            <p className={`text-xl lg:text-base sm:!text-sm font-normal mb-2 duration-500 delay-[600ms] ${isPageLoaded ? 'opacity-100 translate-x-0' : 'opacity-0 -translate-x-1/2'}`}>Managing Director: Mihai Streza-Cirlan</p>
            <p className={`text-xl lg:text-base sm:!text-sm font-normal mb-2 duration-500 delay-[700ms] ${isPageLoaded ? 'opacity-100 translate-x-0' : 'opacity-0 -translate-x-1/2'}`}>Email: finance@wondder.io</p>
          </div>
          {!mobile && <div className='flex mt-8'>
            <a className={`mr-5 lg:mr-3 ${isPageLoaded ? 'translate-y-0 opacity-100 duration-300 ' : '-translate-y-1/2 opacity-0'}`} href="https://www.linkedin.com/company/wondder/" target="_blank" rel="noreferrer" ><img className='w-16 h-16 lg:w-10 lg:h-10' src={LinkedinIcon} /></a>
            <a className={`mx-5 lg:mx-3 ${isPageLoaded ? 'translate-y-0 opacity-100 duration-300 delay-150' : '-translate-y-1/2 opacity-0'}`} href="https://www.instagram.com/wondder.berlin/" target="_blank" rel="noreferrer" ><img className='w-16 h-16 lg:w-10 lg:h-10' src={InstaIcon} /></a>
            <a className={`ml-5 lg:ml-3 ${isPageLoaded ? 'translate-y-0 opacity-100 duration-300 delay-300' : '-translate-y-1/2 opacity-0'}`} href="https://www.facebook.com/wondder.berlin" target="_blank" rel="noreferrer" ><img className='w-16 h-16 lg:w-10 lg:h-10' src={FbIcon} /></a>
          </div>}
        </div>
      </div>
      {mobile && <div className='flex mt-16 justify-between'>
        <a className={`mr-5 lg:mr-3 ${isPageLoaded ? 'translate-y-0 opacity-100 duration-300 ' : '-translate-y-1/2 opacity-0'}`} href="https://www.linkedin.com/company/wondder/" target="_blank" rel="noreferrer" >
          <img className='w-16 h-16' src={LinkedinIcon} />
        </a>
        <a className={`mx-5 lg:mx-3 ${isPageLoaded ? 'translate-y-0 opacity-100 duration-300 delay-150' : '-translate-y-1/2 opacity-0'}`} href="https://www.instagram.com/wondder.berlin/" target="_blank" rel="noreferrer" >
          <img className='w-16 h-16' src={InstaIcon} />
        </a>
        <a className={`ml-5 lg:ml-3 ${isPageLoaded ? 'translate-y-0 opacity-100 duration-300 delay-300' : '-translate-y-1/2 opacity-0'}`} href="https://www.facebook.com/wondder.berlin" target="_blank" rel="noreferrer" >
          <img className='w-16 h-16' src={FbIcon} />
        </a>
      </div>}
      <ContactUsTeamSection />
      <span id='book-a-demo' className='text-[0] pb-10 lg:pb-6 '></span>
      <div className='h-[750px] w-[1050px] xl:h-[550px] xl:w-[500px] self-center sm:!w-[calc(100vw-64px)] bg-white'>
        <iframe
          title='Mihai"s calendar'
          src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ3ol0UdYgSFB2eaG1jCvnM66pHzJPp5gCxTXU0QLMxEKrNyw-GvGO7Ug5T65WswsU32RigpSZ0Z?gv=true&hl=en"
          width="100%" height="100%"></iframe>
      </div>
    </div>
  );
};
